<script setup lang="ts">
import { fetchTrendingProducts, useProductsList } from '~/data/products';
import ProductList from '../plp/ProductList.vue';
import FilterBar from '../filter-bar/index.vue';
import { useRoute } from 'vue-router';
import { useDebounceFn } from '@vueuse/core';
import type { RouteLocationNormalizedLoaded } from 'vue-router';

interface TrendingProductsProps {
	// eslint-disable-next-line vue/require-default-prop
	nodeId?: Array<number>;
	noTitle: boolean;
}

const route = useRoute();

const filters = ref<string>(route.fullPath.split('?')[1] || '');
const props = withDefaults(defineProps<TrendingProductsProps>(), {
	noTitle: false,
});

const enabled = ref(true);

const routeName = computed(() => route.name?.toString());

const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
	useProductsList({
		queryKey: ['trending_products', props.nodeId, routeName, filters],
		queryFn: ({ pageParam: cursor }) => {
			return fetchTrendingProducts(
				cursor as string,
				props.nodeId,
				filters.value,
			);
		},
		enabledValue: enabled,
	});
const disableSignReload = ref(false);
const toggleDisableSignReload = () => {
	disableSignReload.value = !disableSignReload.value;
};
provide('toggleDisableSignReload', toggleDisableSignReload);

const debounce = useDebounceFn((route: RouteLocationNormalizedLoaded) => {
	filters.value = route.fullPath.split('?')[1] || '';
}, 200);

watch(route, (route) => {
	if (
		!['index', 'men', 'kids', 'all-products', 'identifier'].includes(
			route.name as string,
		)
	) {
		enabled.value = false;
	}
	if (route.name === 'products-handle') {
		return;
	}
	debounce(route);
});

watch(disableSignReload, (newValue) => {
	if (newValue) {
		enabled.value = false;
	} else {
		enabled.value = true;
	}
});
</script>
<template>
	<h1
		v-if="!noTitle"
		class="lg:text-2xl text-lg lg:px-0 px-3xl font-semibold text-gray-800"
	>
		Trending Products
	</h1>
	<FilterBar :node-ids="nodeId" :static="true" class="static" />
	<ProductList
		:infinite-loader="true"
		:fetch-next-page="fetchNextPage"
		:is-loading="isLoading"
		:data="data"
		:has-next-page="hasNextPage"
		:is-fetching-next-page="isFetchingNextPage"
	/>
</template>
